import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ButtonCreationEntity } from '@core/models/ButtonCreationEntity';

@Injectable({
  providedIn: 'root',
})
export class OverviewCreationService {
  creationSubject: BehaviorSubject<ButtonCreationEntity | undefined> = new BehaviorSubject<
    ButtonCreationEntity | undefined
  >(undefined);

  getCreationSubject(): ButtonCreationEntity | undefined {
    return this.creationSubject.value;
  }

  setCreationSubject(subj?: ButtonCreationEntity) {
    this.creationSubject.next(subj);
  }
}
